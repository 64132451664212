import { _USER_SERVER } from './../config/config'
import { setPageTitle } from '../utils/common/system'
import { createRouter, createWebHistory, RouteRecordRaw } from 'vue-router'

import { VuexStateKey, VuexModuleKey, VuexActionKey } from '../store/vuexEnum'
import store from '../store'
import _ from 'lodash'
import NProgress from 'nprogress' // progress bar
import { frameworkRoutes, routerBeforeEach, settingRoutesG, recursionGetConstRoute } from '@kzfe/learnable-lib/package/vue3/framework/router/index'
import { initFramework } from '@kzfe/learnable-lib/package/vue3/framework/config/config'
import G, { DOMAIN_MAP } from '../config/config'
import { isLogin } from '@kzfe/learnable-lib/package/vue3/framework/utils/common/user'
import { EnumItem } from '../api/enum'
import Main from '@kzfe/learnable-lib/package/vue3/framework/components/Main/Main.vue'
import { trackRouter } from 'vue-gtag-next'

const routes: Array<RouteRecordRaw> = [
	{
		path: '/test/imgMark',
		name: 'testImgMark',
		component: () => import('../views/test/ImgMark.vue'),
		meta: {
			noLogin: true,
			isConstRoute: true,
		},
	},
	{
		path: '/test/table',
		name: 'testTable',
		component: () => import('../views/test/Table.vue'),
		meta: {
			noLogin: true,
			isConstRoute: true,
		},
	},
	{
		path: '/test/latex',
		name: 'testLatex',
		component: () => import('../views/test/Latex.vue'),
		meta: {
			noLogin: true,
			isConstRoute: true,
		},
	},
	{
		path: '/',
		name: 'home',
		redirect: '/verify/task-list',
		meta: {
			isConstRoute: true,
		},
	},
	{
		path: '/login',
		name: 'login',
		component: () => import('@kzfe/learnable-lib/package/vue3/framework/views/Login.vue'), // 公共库中的登录页
		meta: {
			isConstRoute: true,
			noLogin: true,
		},
	},
	{
		path: '/verify',
		name: 'verify',
		redirect: '/verify/task-list',
		component: Main,
		meta: {
			title: '题目验证',
			icon: '&#xe7d6;',
			permission: 'question-verify',
			menuShow: true,
		},
		children: [
			{
				path: '/verify/task-list',
				name: 'verifyTaskList',
				component: () => import('../views/verify/VerifyTaskList.vue'),
				meta: {
					icon: '&#xe610;',
					title: '数据集验证',
					menuShow: true,
					permission: 'question-verify:verify_task',
				},
			},
			{
				path: '/verify/verify-list',
				name: 'verifyList',
				component: () => import('../views/verify/VerifyList.vue'),
				meta: {
					icon: '&#xe618;',
					title: '验证列表',
					menuShow: false,
					permission: 'question-verify:verify_data',
				},
			},
			{
				path: '/verify/verify-single-list',
				name: 'verifySingleList',
				component: () => import('../views/verify/VerifySingleList.vue'),
				meta: {
					icon: '&#xe67c;',
					title: '单题验证',
					menuShow: true,
					permission: 'question-verify:verify_single_data',
				},
			},
			{
				path: '/verify/verify-detail',
				name: 'verifyDetail',
				component: () => import('../views/verify/VerifyDetail.vue'),
				meta: {
					icon: '&#xe618;',
					title: '验证详情',
					menuShow: false,
					permission: 'question-verify:verify_detail',
				},
			},
			{
				path: '/verify/verify-single-detail',
				name: 'verifySingleDetail',
				component: () => import('../views/verify/VerifySingleDetail.vue'),
				meta: {
					icon: '&#xe618;',
					title: '验证详情',
					menuShow: false,
					permission: 'question-verify:verify_single_detail',
				},
			},
			{
				path: '/verify/case-link-manage',
				name: 'caseLinkManage',
				component: () => import('../views/verify/CaseLinkManage.vue'),
				meta: {
					icon: '&#xe618;',
					title: '外部链接管理',
					menuShow: true,
					permission: 'question-verify:case_link_manage',
				},
			},
			{
				path: '/verify/evaluating-report',
				redirect: '/verify/evaluating-report/list',
				name: 'evaluatingReport',
				meta: {
					icon: '&#xe618;',
					title: '测评报告',
					menuShow: false,
					permission: 'question-verify:evaluating_report',
				},
				children: [
					{
						path: '/verify/evaluating-report/list',
						name: 'evaluatingReportList',
						component: () => import('../views/verify/evaluatingReport/EvaluatingReport.vue'),
						meta: {
							menuShow: false,
							permission: 'question-verify:evaluating_report',
						},
					},
					{
						path: '/verify/evaluating-report/data-result',
						name: 'evaluatingReportNameResult',
						component: () => import('../views/verify/evaluatingReport/EvaluatingReportDataResult.vue'),
						meta: {
							icon: '&#xe618;',
							title: '数据详情',
							menuShow: false,
							permission: 'question-verify:evaluating_report_data_result',
						},
					},
					{
						path: '/verify/evaluating-report/diff-detail',
						name: 'evaluatingReportDiffDetail',
						component: () => import('../views/verify/evaluatingReport/components/DiffComparedDetail.vue'),
						meta: {
							icon: '&#xe618;',
							title: 'Diff详情',
							menuShow: false,
							permission: 'question-verify:evaluating_report_diff_detail',
						},
					},
				],
			},
			{
				path: '/verify/label-management',
				name: 'labelManagement',
				redirect: '/verify/label-management/list',
				meta: {
					icon: '&#xe610;',
					title: '归因标签管理',
					menuShow: true,
					permission: 'question-verify:label_manage',
				},
				children: [
					{
						path: '/verify/label-management/list',
						name: 'labelManagementList',
						meta: {
							menuShow: false,
							permission: 'question-verify:label_manage',
						},
						component: () => import('../views/verify/labelManage/LabelManagement.vue'),
					},
					{
						path: '/verify/attribution-tag',
						name: 'attributionTag',
						meta: {
							icon: '&#xe610;',
							title: '标签管理',
							menuShow: false,
							permission: 'question-verify:label_manage',
						},
						component: () => import('../views/verify/labelManage/AttributionTag.vue'),
					},
				],
			},
			{
				path: '/verify/exerciseVerify',
				name: 'exerciseVerify',
				redirect: '/verify/exerciseVerify/list',
				meta: {
					icon: '&#xe610;',
					title: '练习册验证',
					menuShow: true,
					permission: 'question-verify:exercise_verify',
				},
				children: [
					{
						path: '/verify/exerciseVerify/list',
						name: 'exerciseVerifyList',
						meta: {
							menuShow: false,
							permission: 'question-verify:exercise_verify',
						},
						component: () => import('../views/verify/exerciseVerify/ExerciseVerifyList.vue'),
					},
					{
						path: '/verify/exerciseVerify/verifyList',
						name: 'exerciseVerifyVerifyList',
						meta: {
							menuShow: false,
							permission: 'question-verify:exercise_verify',
							title: '验证列表',
						},
						component: () => import('../views/verify/exerciseVerify/TaskVerifyList.vue'),
					},
				],
			},
		],
	},
	{
		path: '/dataset',
		name: 'dataset',
		component: Main,
		redirect: '/dataset/set-list',
		meta: {
			title: '数据集管理',
			icon: '&#xe62b;',
			permission: 'data-set',
			menuShow: true,
		},
		children: [
			{
				path: '/dataset/set-list',
				name: 'datasetSetList',
				component: () => import('../views/dataset/SetList.vue'),
				meta: {
					icon: '&#xe641;',
					title: '数据集列表',
					menuShow: true,
					permission: 'data-set:manage',
				},
			},
			{
				path: '/dataset/data-list',
				name: 'datasetDataList',
				component: () => import('../views/dataset/DataList.vue'),
				meta: {
					icon: '',
					title: '数据列表',
					menuShow: false,
					permission: 'data-set:data-list',
				},
			},
			{
				path: '/dataset/import-picture',
				name: 'datasetImportPicture',
				component: () => import('../views/dataset/ImportPicture.vue'),
				meta: {
					icon: '',
					title: '导入图片管理',
					menuShow: false,
					permission: 'data-set:import_picture',
				},
			},
		],
	},
	{
		path: '/smartgrading',
		name: 'smartgrading',
		component: Main,
		redirect: '/smartgrading/dataset-manage',
		meta: {
			title: '开放式批改',
			icon: '&#xe62b;',
			permission: 'smartgrading',
			menuShow: true,
		},
		children: [
			{
				path: 'dataset-manage',
				name: 'sg-dataset-manage',
				component: () => import('../views/smartgradingmg/SetList.vue'),
				meta: {
					icon: '&#xe641;',
					title: '数据集管理',
					menuShow: true,
					permission: 'smartgrading:dataset-manage',
				},
			},
			{
				meta: {
					icon: '&#xe641;',
					title: '数据集管理 / 数据列表',
					menuShow: false,
					permission: 'smartgrading:dataset-manage:detail',
				},
				path: 'dataset-manage/detail',
				name: 'sg-dataset-manage-detail',
				component: () => import('../views/smartgradingmg/DataList.vue'),
			},
			{
				path: 'dataset-manage/detail/verify',
				name: 'sg-dataset-manage-detail-verify',
				component: () => import('../views/smartgradingbf/picture-detail/index.vue'),
				meta: {
					icon: '&#xe641;',
					title: '数据集管理 / 数据列表 / 数据校对',
					menuShow: false,
					permission: 'smartgrading:dataset-manage:detail',
				},
			},

			{
				path: '/smartgrading/dataset-verify',
				name: 'sg-dataset-verify',
				component: () => import('../views/smartgradingvf/VerifyTaskList.vue'),
				meta: {
					icon: '&#xe641;',
					title: '数据集验证',
					menuShow: true,
					permission: 'smartgrading:dataset-verify',
				},
			},

			{
				path: '/smartgrading/dataset-verify/list',
				name: 'sg-dataset-verify-list',

				component: () => import('../views/smartgradingvf/VerifyList.vue'),
				meta: {
					icon: '&#xe641;',
					title: '数据集验证 / 验证列表',
					menuShow: false,
					permission: 'smartgrading:dataset-verify:list',
				},
			},
			{
				path: '/smartgrading/verify-detail',
				name: 'sg-dataset-verify-detail',
				component: () => import('../views/smartgradingbf/picture-detail/VerifyDetail.vue'),
				meta: {
					icon: '&#xe618;',
					title: '验证详情',
					menuShow: false,
					permission: 'question-verify:verify_detail',
				},
			},
			{
				path: '/smartgrading/dataset-backflow',
				name: 'sg-dataset-backflow',
				component: () => import('../views/smartgradingbf/picture-ocr/index.vue'),
				meta: {
					icon: '&#xe641;',
					title: '数据回流',
					menuShow: true,
					permission: 'smartgrading:dataset-backflow',
				},
			},
			{
				path: '/smartgrading/dataset-backflow/detail',
				name: 'sg-dataset-backflow-detail',
				component: () => import('../views/smartgradingbf/picture-detail/backflow.vue'),
				meta: {
					icon: '&#xe641;',
					title: '数据回流 / 数据详情',
					menuShow: false,
					permission: 'smartgrading:dataset-backflow',
				},
			},
		],
	},

	{
		path: '/testdata',
		name: 'testdata',
		redirect: '/testdata/review-result',
		component: Main,
		meta: {
			title: '考试数据',
			icon: '&#xe889;',
			permission: 'testdata',
			menuShow: true,
		},
		children: [
			{
				path: '/testdata/review-result',
				name: 'testdataReviewResult',
				component: () => import('../views/testdata/reviewResult/ReviewResult.vue'),
				meta: {
					icon: '&#xe889;',
					title: '考试批阅结果查询',
					menuShow: true,
					permission: 'testdata:review_result',
				},
			},
			{
				path: '/testdata/subject-detail',
				name: 'tdSubjectDetail',
				component: () => import('../views/testdata/subjectDetail/index.vue'),
				meta: {
					icon: '&#xe889;',
					title: '学科情况',
					menuShow: true,
					permission: 'testdata:subject-detail',
				},
			},
			// {
			// 	path: '/testdata/single-subject-detail',
			// 	name: 'tdSingleSubjectDetail',
			// 	component: () => import('@/views/testdata/reviewResult/ReviewResult.vue'),
			// 	meta: {
			// 		icon: '&#xe889;',
			// 		title: '单科详情',
			// 		menuShow: true,
			// 		permission: 'testdata:single-subject-detail',
			// 	},
			// },
			{
				path: '/testdata/review-detail',
				name: 'testdataDetail',
				component: () => import('../views/testdata/reviewResult/ReviewDetail.vue'),
				meta: {
					icon: '&#xe61b;',
					title: '考试结果详情',
					menuShow: false,
					permission: 'testdata:review_detail',
				},
			},
		],
	},
	{
		path: '/homework',
		name: 'homework',
		redirect: '/homework/review-result',
		component: Main,
		meta: {
			title: '作业数据',
			icon: '&#xe619;',
			permission: 'homework',
			menuShow: true,
		},
		children: [
			{
				path: '/homework/global-result',
				name: 'homeworkGlobalResult',
				component: () => import('../views/homework/globalResult/GlobalResult.vue'),
				meta: {
					icon: '&#xe7d6;',
					title: '整体情况',
					menuShow: true,
					permission: 'homework:global_result',
				},
			},
			{
				path: '/homework/subject-result',
				name: 'homeworkSubjectResult',
				component: () => import('../views/homework/subjectResult/SubjectResult.vue'),
				meta: {
					icon: '&#xe7d6;',
					title: '单科详情',
					menuShow: true,
					permission: 'homework:subject_result',
				},
			},
			{
				path: '/homework/sampling-config',
				name: 'homeworkSamplingConfig',
				component: () => import('../views/homework/samplingConfig/SamplingConfig.vue'),
				meta: {
					icon: '&#xe7d6;',
					title: '采样配置',
					menuShow: true,
					permission: 'homework:sampling_config',
				},
			},
			{
				path: '/homework/review-result',
				name: 'reviewResult',
				component: () => import('../views/homework/reviewResult/ReviewResult.vue'),
				meta: {
					icon: '&#xe61b;',
					title: '作业判题结果查询',
					menuShow: true,
					permission: 'homework:review_result',
				},
			},
			{
				path: '/homework/review-detail',
				name: 'reviewDetail',
				component: () => import('../views/homework/reviewResult/ReviewDetail.vue'),
				meta: {
					icon: '&#xe61b;',
					title: '作业结果详情',
					menuShow: false,
					permission: 'homework:review_detail',
				},
			},
			{
				path: '/homework/teacherFeedback',
				name: 'teacherFeedback',
				component: () => import('../views/homework/teacherFeedback/TeacherFeedBack.vue'),
				meta: {
					icon: '&#xe606;',
					title: '教研反馈',
					menuShow: true,
					permission: 'homework:teacher_feedback',
				},
			},
			{
				path: '/homework/questionNumberList',
				name: 'questionNumberList',
				component: () => import('../views/homework/questionNumberList/QuestionNumberList.vue'),
				meta: {
					icon: '&#xe637;',
					title: '作业题目反馈数量统计',
					menuShow: true,
					permission: 'homework:question_number',
				},
			},
			{
				path: '/feedback/statistics',
				name: 'feedbackStatistics',
				component: () => import('../views/feedback/statistics/Statistics.vue'),
				meta: {
					icon: '&#xe637;',
					title: '反馈统计',
					menuShow: true,
					permission: 'homework:feedback_statistics',
				},
			},
			{
				path: '/feedback/statisticsDetail',
				name: 'feedbackStatisticsDetail',
				component: () => import('../views/feedback/statistics/StatisticsDetail.vue'),
				meta: {
					icon: '&#xe637;',
					title: '反馈记录',
					menuShow: false,
					permission: 'homework:feedback_statistics',
				},
			},
			{
				path: '/homework/attributionStatistics',
				name: 'homeworkAttributionStatistics',
				component: () => import('../views/homework/attributionStatistics/AttributionStatistics.vue'),
				meta: {
					icon: '&#xe637;',
					title: '归因统计',
					menuShow: true,
					permission: 'homework:attribution_statistics',
				},
			},
			{
				path: '/homework/attributionDetail',
				name: 'homeworkAttributionDetail',
				component: () => import('../views/homework/attributionDetail/AttributionDetail.vue'),
				meta: {
					icon: '&#xe637;',
					title: '归因详情',
					menuShow: false,
					permission: 'homework:attribution_detail',
				},
			},
			{
				path: '/homework/mixtureGrader',
				name: 'homeworkMixtureGrader',
				component: () => import('../views/homework/mixtureGrader/data.vue'),
				meta: {
					icon: '&#xe637;',
					title: '混合批改数据',
					menuShow: true,
					permission: 'homework:mixture-grader-data',
				},
			},
			{
				path: '/homework/mixtureCheck',
				name: 'homeworkMixtureCheck',
				component: () => import('../views/homework/mixtureGrader/check.vue'),
				meta: {
					icon: '&#xe637;',
					title: '混合批改抽样',
					menuShow: true,
					permission: 'homework:mixture-grader-check',
				},
			},
		],
	},
	{
		path: '/picture',
		name: 'picture',
		redirect: '/picture/picture-ocr',
		component: Main,
		meta: {
			title: '图片数据',
			icon: '&#xe889;',
			permission: 'picture',
			menuShow: true,
		},
		children: [
			{
				path: '/picture/picture-quality',
				name: 'pictureQuality',
				component: () => import('../views/picture/picture-quality/index.vue'),
				meta: {
					icon: '&#xe889;',
					title: '图像质量校验',
					menuShow: true,
					permission: 'picture:picture_quality',
				},
			},
			{
				path: '/picture/picture-ocr',
				name: 'pictureOcr',
				component: () => import('../views/picture/picture-ocr/index.vue'),
				meta: {
					icon: '&#xe61c;',
					title: '作业图像识别',
					menuShow: true,
					permission: 'picture:picture_ocr',
				},
			},
			{
				path: '/picture/picture-detail',
				name: 'pictureDetail',
				component: () => import('../views/picture/picture-detail/index.vue'),
				meta: {
					icon: '&#xe61c;',
					title: '学生图片详情',
					menuShow: false,
					permission: 'picture:picture_detail',
				},
			},
		],
	},
	{
		path: '/question',
		name: 'question',
		redirect: '/question/question-list',
		component: Main,
		meta: {
			title: '题目数据',
			icon: '&#xe7f4;',
			permission: 'question',
			menuShow: true,
		},
		children: [
			{
				path: '/question/question-list',
				name: 'questionList',
				component: () => import('../views/question/question-list/index.vue'),
				meta: {
					icon: '&#xe601;',
					title: '题目列表',
					menuShow: true,
					permission: 'question:question_list',
				},
			},
			{
				path: '/question/question-detail',
				name: 'questionDetail',
				component: () => import('../views/question/question-detail/index.vue'),
				meta: {
					icon: '&#xe601;',
					title: '单题批改列表',
					menuShow: false,
					permission: 'question:question_detail',
				},
			},
			{
				path: '/question/question-dataset',
				name: 'questionDataset',
				redirect: '/question/question-dataset-list',
				// component: () => import('../views/question/question-dataset-list/index.vue'),
				meta: {
					icon: '&#xe601;',
					title: '单题数据集列表',
					menuShow: true,
					permission: 'question:question_dataset_list',
				},
				children: [
					{
						path: '/question/question-dataset-list',
						name: 'questionDatasetList',
						component: () => import('../views/question/question-dataset-list/index.vue'),
						meta: {
							menuShow: false,
						},
					},
					{
						path: '/question/question-dataset-detail',
						name: 'questionDatasetDetail',
						component: () => import('../views/question/question-dataset-detail/index.vue'),
						meta: {
							menuShow: false,
							title: '数据列表',
							permission: 'question:question_dataset_list',
						},
					},
				],
			},
			{
				path: '/question/question-dataset-verify',
				name: 'questionDatasetVerify',
				redirect: '/question/question-dataset-verify-list',
				// component: () => import('../views/question/question-dataset-verify/index.vue'),
				meta: {
					icon: '&#xe601;',
					title: '单题数据集验证',
					menuShow: true,
					permission: 'question:question_dataset_verify',
				},
				children: [
					{
						path: '/question/question-dataset-verify-list',
						name: 'questionDatasetVerifyList',
						component: () => import('../views/question/question-dataset-verify/index.vue'),
						meta: {
							menuShow: false,
						},
					},
					{
						path: '/question/question-dataset-verify-detail',
						name: 'questionDatasetVerifyDetail',
						component: () => import('../views/question/question-dataset-verify-detail/index.vue'),
						meta: {
							menuShow: false,
							title: '验证列表',
							permission: 'question:question_dataset_verify',
						},
					},
				],
			},
		],
	},
	{
		path: '/book',
		name: 'book',
		redirect: '/book/book-list',
		component: Main,
		meta: {
			title: '书本数据',
			icon: '&#xe70a;',
			permission: 'book',
			menuShow: true,
		},
		children: [
			{
				path: '/book/book-list',
				name: 'bookList',
				component: () => import('../views/book/book-list/index.vue'),
				meta: {
					icon: '&#xe60a;',
					title: '书本列表',
					menuShow: true,
					permission: 'book:book_list',
				},
			},
			{
				path: '/book/book-detail',
				name: 'bookDetail',
				component: () => import('../views/book/book-detail/index.vue'),
				meta: {
					icon: '&#xe60a;',
					title: '书本详情',
					menuShow: false,
					permission: 'book:book_detail',
				},
			},
		],
	},
	...frameworkRoutes,
]

export const constRoutes = recursionGetConstRoute(routes)
const router = createRouter({
	history: createWebHistory(import.meta.env.BASE_URL),
	routes: constRoutes,
})
trackRouter(router)

export function settingRoutes() {
	settingRoutesG(router, routes)
}

initFramework(import.meta.env, G, store, router, DOMAIN_MAP, settingRoutes)

export default router

NProgress.configure({ showSpinner: false })

router.beforeEach((to, from, next) => {
	NProgress.start()
	routerBeforeEach(to, from, next)
})

router.afterEach(to => {
	NProgress.done()
	setPageTitle(to.meta.title)
	if (isLogin()) {
		const module = store.state[VuexModuleKey.enumModule] as {
			[x: string]: unknown
		}
		if (module) {
			const serverEnum = module[VuexStateKey.serverAllEnum] as {
				[key: string]: EnumItem
			}
			const keys = Object.keys(serverEnum)
			if (!keys.length) {
				store.dispatch(`${VuexModuleKey.enumModule}/${VuexActionKey.queryAllEnum}`)
				store.dispatch(`${VuexModuleKey.enumModule}/${VuexActionKey.queryHomeworkAllEnum}`)
			}
		}
	}
})
